import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight  } from '@fortawesome/free-solid-svg-icons'
import './Slide3.scss'

class Slide3 extends Component{
    constructor(props) {
        super(props)
        this.handleResize = this.handleResize.bind(this)
        this.state = {
            width: 1200,
            height: 600,
            prevIndex: -1,
            nextIndex: 1,
            currentIndex: 0,
            translateValue: -480
        }
    }

    componentDidMount() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize= e => {
        const width = document.getElementById('slider3').offsetWidth
        if(width!==this.state.width && width<1200){
            this.setState({
                width: width,
                height: width/2,
                translateValue: -0.4*width + this.state.currentIndex*0.6*width
            })
        }

    }

    goPrev = () => {
        if(this.state.currentIndex > 0){
            this.setState(prevState => ({
                prevIndex: prevState.currentIndex,
                currentIndex : prevState.currentIndex - 1,
                nextIndex: prevState.currentIndex - 2,
                translateValue: prevState.translateValue - (this.state.width*0.6)
            }));
        }else{
            this.setState(prevState => ({
                prevIndex: prevState.currentIndex,   
                currentIndex : this.props.images.length-1,
                translateValue: 0.6*this.state.width*24-0.4*this.state.width   
            }))
        }
    }

    goNext = () => {
        if(this.state.currentIndex < this.props.images.length-1){
            this.setState(prevState => ({
                prevIndex: prevState.currentIndex,
                nextIndex: prevState.currentIndex+2,
                currentIndex : prevState.currentIndex + 1,
                translateValue: prevState.translateValue + (this.state.width*0.6)
            }));
         
        }else{
            this.setState(prevState => ({
                prevIndex: prevState.currentIndex,
                currentIndex : 0,
                translateValue: -0.4*this.state.width
            }))

        }
    }

    render(){
        console.log(this.props.images)
        const Slide = this.props.images.map((image,index) =>
            <div className={`slide slide-${index}`} key={index} style={{backgroundImage:`url(${image})`}}></div>    
        )

        const RightArrow = () => {
            return (
               <FontAwesomeIcon  className="load-btn" icon={faChevronCircleRight} onClick={()=>this.goNext()} style={{position:"absolute",color:"black",right:10,top:"40%",height:40,width:40,textAlign:"center",zIndex:99}}/>
            );
          }
          
          
          const LeftArrow = () => {
            return (
              <FontAwesomeIcon  className="load-btn"icon={faChevronCircleLeft} onClick={()=>this.goPrev()} style={{position:"absolute",color:"black",left:10,top:"40%",height:40,width:40,textAlign:"center",zIndex:99}}/>
            );
          }

        return(
            <div id="slider3" className={`slider3 active-slide-${this.state.currentIndex} prevactive-slide-${this.state.prevIndex} nextactive-slide-${this.state.nextIndex}`} style={{height:this.state.height, maxWidth:this.state.width}}>
                <LeftArrow />
                <RightArrow />  
                <div className="slider3-wrapper" style={{transform: `translateX(${this.state.translateValue-this.state.currentIndex*(this.state.width*1.2)}px)`}}>
                    <div className={`slide slide-${this.state.prevIndex}`} key={this.state.prevIndex} style={{backgroundImage:`url(${this.props.images[5]})`}}></div>  
                    {Slide}
                    <div className={`slide slide-${this.state.nextIndex}`} key={this.state.nextIndex} style={{backgroundImage:`url(${this.props.images[5]})`}}></div>

                </div>
                
            </div>
        )
    }
}

export default Slide3