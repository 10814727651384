import React, { useState } from 'react';
import Slide3 from './Slide3.js'

function App() {
  const [margin, setMargin] = useState('0px');
  const [images] = useState(importAll(require.context('../public/imgs/arts', false, /\.(png|jpe?g|svg)$/)))
  const [placeImages] = useState(importAll(require.context('../public/imgs/place', false, /\.(png|jpe?g|svg)$/)))
  const [count, setCount] = useState( 0 )
  const [menu, setMenu] = useState( 'menu-body display-none' )
  const [menuTrigger, setMenuTrigger] = useState( 'menu-open menu' )
  const [menuWrapper, setMenuWrapper] = useState( 0.5 )
  const [menuOpen, setMenuOpen] = useState( false )

  function importAll(r) {
    return r.keys().map(r);
  }
  const loadMore=()=>{
    if(window.innerWidth<600){
      if(count<images.length-1){
        let m = 300*(count+1)
        setMargin(`-${m}px`)
        setCount(count+1)
      }
    }
    else if(window.innerWidth<1100){
      if(count<images.length/2-1){
        let m = 300*(count+1)
        setMargin(`-${m}px`)
        setCount(count+1)
      }
    }else{
      if(count<images.length/3-1){
        let m = 300*(count+1)
        setMargin(`-${m}px`)
        setCount(count+1)
      }
    }
  }
  const loadLess=()=>{
    if(window.innerWidth<600){
      if(count>=1){
        let m = 300*(count-1)
        setMargin(`-${m}px`)
        setCount(count-1)
      }
    }
    else if(window.innerWidth<1100){
      if(count>=1){
        let m = 300*(count-1)
        setMargin(`-${m}px`)
        setCount(count-1)
      }
    }else{
      if(count>=1){
        let m = 300*(count-1)
        setMargin(`-${m}px`)
        setCount(count-1)
      }
    }
  }
  const changeMenu=()=>{
    if(menuOpen){
      setMenuTrigger('menu-open menu')
      setMenu('menu-body display-none')
      setMenuWrapper('0.5')
      setMenuOpen(false)
    }else{
      setMenuTrigger('menu-close menu')
      setMenu('menu-body display-block')
      setMenuWrapper('0')
      setMenuOpen(true)
    }
  }
  const imgs = images.map((img,index)=>
    <li className="art-thunb" key={index} > 
      <div style={{backgroundImage:`url(${img})`}}></div>
    </li>
  )
  return (
    <div className="App" >
      
      <div className={menu}>
        <div className="menu-bg" onClick={()=>changeMenu()}></div>
        <ul className="menu-list">
          <li><a href="#about-body">简介</a></li>
          <li><a href="#art-body">作品集</a></li>
          <li><a href="#contact-body">关于我们</a></li>
          <li><a href="#contact-body">联系方式</a></li>
        </ul>
      </div>
      <div className="menu-wrapper" style={{backgroundColor:`rgba(255,255,255,${menuWrapper})`}}>
        <div className={menuTrigger} onClick={()=>changeMenu()}>
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </div>
      </div>
      <div className="menu-frame">
        <div></div>
      </div>
      <div className="body" >
      
        <div className="home-body">
          <div className="body-left">
            <img alt="coroArt-logo" src="/imgs/logo.png" />
          </div>

          <div className="body-right">
            <div className="body-description">
              <h5><b>徐白一教授</b></h5>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;中国著名工笔山水画家，吉林大学中国古典绘画研究所所长，教授。2019年3月27号至4月8号在中国美术馆成功举办“大美长白 情系品园--徐白一工笔山水画展”。</p>

              <p>&nbsp;&nbsp;&nbsp;&nbsp;徐白一化作得到美术理论界专家们的高度评价和认可，称其开创了中国东北平原山水画派，病为此画派奠定了理论基础。</p>
            </div>
            <img alt="coroArt-bg" src="/imgs/bg1.jpg" />
            <br /><br />
            <h1><b>徐白一泼彩山水小品展</b></h1>
          </div>

        </div>
      </div>
      
      <div className="about-body" id="about-body" style={{backgroundImage:'url("/imgs/bg2.jpg")'}}>
          <div className="about-bg">
            <div className="about-description">
              <h2><b>徐白一教授 简介</b></h2>
              <img alt="coroArt-bg" src="/imgs/person.jpg" align="right"/>
              <p>徐白一，字古沙，号天佐人客，1958年生于吉林省长春市，毕业于东北师范大学美术学院，吉林大学应用技术学院中国古典绘画研究所所长，艺术系教授。</p>
              <p>徐白一自1989年起游历日本韩国欧美等国，2001年移民定居纽约。长期从事美术专业的教学和对中国古典绘画的研究，2008年于吉林大学创办中国古典绘画研究所任所长，教授。</p>
              <p>创作上主张回归古典，提出“中国画是在一个文化精神状态下的两套标准和以诗书画为一体的文人画标准，而文人画只是中国画的一个组成部分，绝不是中国画的主干标准和唯一标准。”通过近四十年的研究，徐白一在理论上总结出中国东北的审美精神是有溜达要素构成，即：大，平，广，远，荒，冷！对应着六大要素，建立起一套相对应得绘画形式上的于洋表现体系，近年来他以宋人绘画之法理，融合西方绘画构图的客观性等要素，用古今中外绘画的有用之法，潜心研究表现中国东北的平原风貌之景色，开东北大地平原绘画之先河，尤为善于表现北方平原冰雪，其座屏气势恢宏，清气满天，大气磅礴，震人心魄。出版有《徐白一画集》，《徐白一工笔水画集》，《广远堂艺话》，《徐白一》文集等。</p>
            </div>
          </div>
      </div>

      <div className="body t-c" id="art-body">
        <br />
        <h1><b>作品集</b></h1>
        <br />
        <div className="art-body">
           <ul style={{top:margin}}>
              {imgs}
           </ul>
        </div>
        <br />
        
      </div>

      <div id="contact-body" className="body t-c" style={{paddingBottom:0,backgroundImage:"url('imgs/landscape.png')",backgroundSize:"100% 100%",backgroundPosition:"center"}}>  
        <h1><b>关于我们</b></h1>
        
        <div className="contact-body" >
          <Slide3 images={placeImages} />

          <div className="row">
            <div className="col-md-4">
              <br />
              <h5><b>地点</b></h5>
              50-24 98th Street<br />
               Corona, NY 11368<br />
            </div>
            <div className="col-md-4">
              <br />
              <h5><b>时间</b></h5>
                Mon - Sat<br/ >11:00am - 5:00pm <br />
                Sunday<br /> Closed <br />
            </div>
            <div className="col-md-4">
              <br />
              <h5><b>电话</b></h5>
                (718) 803-1262
            </div>
          </div>
        </div>
        <div ></div>
      </div>
    </div>
  );
}

export default App;
